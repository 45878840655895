import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const CATEGORY_GENERIC_PAGE_HEADER_FRAGMENT = `
  fragment categoryGenericPageHeaderFragment on Category {
    uid
    name
    bannerFileResource {
      ...fileResourceBaseFragment
    }
    __typename
  }
    ${FILE_RESOURCE_BASE_FRAGMENT}
`;
